
import webC from './html-component.js'

const { html } = webC

class Notifier extends webC.WebComponent {
  constructor () {
    super()
    const count = this.get('notification-length')
    this.innerHTML = html`
    <a class="c-btn a-rounded/50 a-bg/concave:hover a-bg/concave:active a-bg/convex a-bg-blur/base c-notification/toggler" style="z-index: 0;">
      <i class="bi bi-bell"></i> <b data-bind="nToggler" id="nToggler">Notifications</b> <span data-bind="count" id="count" class="c-badge a-bg-color/primary">${Number(count) ? count : ''}</span>
    </a>
    `
    this.state = { closed: true }
  }

  setCount (n) {
    const count = n ? String(n) : this.get('notification-length')
    this.count = Number(count) ? `<span class="c-badge a-bg-color/primary">${count}</span>` : ''
  }

  render () {
    if (this.state.closed) {
      this.setState({ nToggler: 'Notifications' })
      this.query('a').classList.remove('focus')
    } else {
      this.setState({ nToggler: 'Click to Close' })
      this.query('a').classList.add('focus')
      this.show(document.querySelectorAll('notifier-notification'))
    }
    return this
  }

  onClick = (target) => {
    this.state.closed ? (this.state.closed = false) : (this.state.closed = true)
    this.render()
  }
}

class NotifierMinimize extends webC.WebComponent {
  constructor () {
    super()
    const id = this.get('data-notifier')
    this.state = { id }
    this.innerHTML = html`
      <button class="c-btn c-btn/warning u-float/right u-mr/system-50">-</button>
    `
  }

  onClick = (target) => {
    this.hide(document.querySelector(`[notification-id="${this.state.id}"]`))
  }
}

class NotifierDelete extends webC.WebComponent {
  constructor () {
    super()
    const id = this.get('data-notifier')
    this.state = { id }
    this.innerHTML = html`
      <button class="c-btn c-btn/danger u-float/right">X</button>
    `
  }

  onClick = (target) => {
    console.log(this.state.id)
    const notification = document.querySelector(`[notification-id="${this.state.id}"]`)
    fetch(`/notifier/delete/${this.state.id}`)
      .then(response => {
        console.log(response)
        notification.remove()
        localStorage.removeItem(this.state.id)
        const notificationsUpdated = document.querySelectorAll('.c-notification')
        const notifier = document.querySelector('notifier-toggle')
        notifier.setState({ count: notificationsUpdated.length ? notificationsUpdated.length : '' })
        notifier.setStyle(notifier.query('.c-badge'), {
          display: notificationsUpdated.length ? 'initial' : 'none'
        })
      })
      .catch(err => {
        console.error(err)
      })
  }
}

class NotifierNotification extends webC.WebComponent {
  constructor () {
    super()
    const id = this.get('notification-id')
    const color = this.get('n-color')
    this.innerHTML = html`
    <div class="c-notification ${color} u-my/system-75 a-bg/convex" data-notification="successDemo">
      <div class="l-row u-mt/0">
        <div class="l-column/7 u-my/0">
          <p class="t-type/title u-display/block u-mx/system-50" data-bind="title"></p>
          <p class="t-type/secondary u-display/block u-float/none u-mx/system-50" data-bind="date"></p>
        </div>
        <div class="l-column/5 u-my/0">
          <notifier-delete data-notifier="${id}"></notifier-delete>
          <notifier-minimize data-notifier="${id}"></notifier-minimize>
        </div>
        <div class="l-column/12">
          <p class="t-type/body u-mx/system-50 u-my/0">${this.get('n-message')}</p>
        </div>
      </div>
    </div>
    `
  }

  render () {
    this.setState({
      id: this.get('notification-id'),
      title: this.get('n-title'),
      date: this.get('n-date')
    })
  }
}

class NotifierExample extends webC.WebComponent {
  constructor () {
    super()
    this.state = { color: 'a-bg-blur/base' }
    this.innerHTML = html`
    <div class="c-notification ${this.state.color} u-my/system-75 a-bg/convex" data-notification="successDemo">
      <div class="l-row u-mt/0">
        <div class="l-column/8 u-my/0">
          <p class="t-type/title u-display/block u-mx/system-50" data-bind="title"></p>
          <p class="t-type/secondary u-display/block u-float/none u-mx/system-50" data-bind="date"></p>
        </div>
        <div class="l-column/4 u-my/0">
          <button class="c-btn c-btn/danger u-float/right">X</button>
        </div>
        <div class="l-column/12">
          <p class="t-type/body u-mx/system-50 u-my/0" data-bind="message"></p>
        </div>
      </div>
    </div>
    `
  }

  render () {
    this.setState({
      title: 'Preview',
      message: 'Fill out the form to see changes.',
      date: new Date().toDateString()
    })
  }
}

webC.define('notifier-minimize', NotifierMinimize)
webC.define('notifier-delete', NotifierDelete)
webC.define('notifier-toggle', Notifier)
webC.define('notifier-notification', NotifierNotification)
webC.define('notifier-example', NotifierExample)

const handleNotifications = (notifications) => {
  const { length } = notifications
  const icon = '/Images/256.png'
  let i = 0
  // Using an interval cause some browsers (including Firefox) are blocking notifications if there are too much in a certain time.
  const interval = window.setInterval(() => {
    const notification = notifications[i]
    if (!notification) return
    const body = notification.get('n-message')
    const id = notification.getState('id')
    if (!localStorage.getItem(id)) {
      const n = new Notification(notification.getState('title'), { icon, body, tag: 'soManyNotification' })
      localStorage.setItem(id, 'ran')
      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
          // The tab has become visible so clear the now-stale Notification.
          n.close()
        }
      })
    }
    if (i++ === length) {
      window.clearInterval(interval)
    }
  }, 200)
}

const requestNotification = () => {
  const permission = Notification.permission
  if (permission === 'default') {
    Notification
      .requestPermission()
      .then(result => {
        console.log(result)
        if (result === 'granted') handleNotifications(document.querySelectorAll('notifier-notification'))
      })
      .catch(err => {
        console.error(err)
      })
  } else if (permission === 'granted') {
    handleNotifications(document.querySelectorAll('notifier-notification'))
  }
}

if (document.querySelector('notifier-notification')) {
  requestNotification()
}

if (document.querySelector('notifier-example')) {
  const title = document.querySelector('#title')
  const message = document.querySelector('#message')
  const color = document.querySelector('#color')
  const exampleNotification = document.querySelector('notifier-example')
  title.addEventListener('change', () => {
    console.log()
    exampleNotification.setState({ title: title.value })
  })
  message.addEventListener('change', () => {
    console.log()
    exampleNotification.setState({ message: message.value })
  })
  color.addEventListener('change', () => {
    console.log()
    exampleNotification.query('.c-notification').classList.remove(exampleNotification.getState('color'))
    exampleNotification.setState({ color: `a-bg-blur/${color.value}` })
    exampleNotification.query('.c-notification').classList.add(exampleNotification.getState('color'))
  })
}
