
import * as dt from '@neumatter/datetime'

if (typeof globalThis.DateTime === 'undefined') {
  globalThis.DateTime = dt.DateTime
}

if (typeof globalThis.Duration === 'undefined') {
  globalThis.Duration = dt.Duration
}

if (typeof globalThis.Calendar === 'undefined') {
  globalThis.Calendar = dt.Calendar
}

if (typeof globalThis.PlainDate === 'undefined') {
  globalThis.PlainDate = dt.PlainDate
}

if (typeof globalThis.PlainTime === 'undefined') {
  globalThis.PlainTime = dt.PlainTime
}

if (typeof globalThis.TimeZone === 'undefined') {
  globalThis.TimeZone = dt.TimeZone
}

/** @typedef {Window & { DateTime: typeof dt.DateTime, Duration: typeof dt.Duration, Calendar: typeof dt.Calendar, PlainDate: typeof dt.PlainDate, PlainTime: typeof dt.PlainTime, TimeZone: typeof dt.TimeZone }} GlobalThis */
