export default function Q$ (selector) {
  class Query {
    constructor (selector) {
      this.selector = selector
      if (selector === 'document') {
        this.els = [document]
      } else if (selector === 'window') {
        this.els = [window]
      } else if (selector === 'docelement') {
        this.els = [document.documentElement]
      } else {
        this.els =
          typeof selector === 'object'
            ? new Array(this.selector)
            : document.querySelectorAll(this.selector)
      }
    }

    each (callback) {
      if (!callback || typeof callback !== 'function') {
        return
      }
      for (let i = 0; i < this.els.length; i++) {
        callback.call(this.els, this.els[i], i)
      }
      return this
    }

    id (v) {
      if (!v) {
        let str = ''
        this.each(els => (str = els.id))
        return str
      } else {
        this.each(els => (els.id = v))
        return this
      }
    }

    value (v) {
      if (!v) {
        let str = ''
        this.each(els => (str = els.value))
        return str
      } else {
        this.each(els => (els.value = v))
        return this
      }
    }

    min (v) {
      if (!v) {
        let str = ''
        this.each(els => (str = els.min))
        return str
      } else {
        this.each(els => (els.min = v))
        return this
      }
    }

    innerWidth () {
      const el = { any: '' }
      this.each(els => {
        el.any = els.innerWidth
      })
      return el.any
    }

    text () {
      const el = { any: '' }
      this.each(els => {
        el.any = els.textContent
      })
      return el.any
    }

    textEmpty () {
      if (/formulas/.test(Q$('window').location())) {
        this.each(els => {
          if (els.textContent === '') {
            els.remove()
          }
        })
      }
      return this
    }

    typeSize (v) {
      const s = { tV: '' }
      this.each(els => {
        if (!v) {
          s.tV = els.style.fontSize
        } else {
          els.style.fontSize = v
          s.tV = this
        }
      })
      return s.tV
    }

    searchVal () {
      const el = { any: '' }
      this.each(els => {
        el.any = els.target.value.toLowerCase()
      })
      return el.any
    }

    isFocus () {
      let str = ''
      this.each(el => {
        if (el === document.activeElement) {
          str = true
        } else {
          str = false
        }
      })
      return str
    }

    check () {
      this.each(els => (els.checked = true))
      return this
    }

    uncheck () {
      this.each(els => (els.checked = false))
      return this
    }

    disable () {
      this.each(els => (els.disabled = true))
      return this
    }

    able () {
      this.each(els => (els.disabled = false))
      return this
    }

    transform (transformation) {
      this.each(els => (els.style.transform = transformation))
      return this
    }

    lowerCase () {
      this.each(els => els.toLowerCase())
      return this
    }

    show () {
      this.each(els => (els.style.display = 'initial'))
      return this
    }

    hide () {
      this.each(els => (els.style.display = 'none'))
      return this
    }

    hasClass (css) {
      let rtn = ''
      this.each(els => {
        if (els.classList.contains(css)) {
          rtn = true
        } else {
          rtn = false
        }
      })
      return rtn
    }

    addClass (css) {
      this.each(els => els.classList.add(css))
      return this
    }

    removeClass (css) {
      this.each(els => els.classList.remove(css))
      return this
    }

    swapClass (cssR, cssA) {
      this.each(els => {
        els.classList.remove(cssR)
        els.classList.add(cssA)
      })
      return this
    }

    selectedOption () {
      let rtnThis = 0
      this.each(els => {
        rtnThis = els.options.selectedIndex
      })
      return rtnThis
    }

    getOptions () {
      const rtnThis = []
      this.each(els => rtnThis.push(...Array.from(els.options)))
      return rtnThis
    }

    removeOption (ind) {
      this.each(els => els.options.remove(ind))
      return this
    }

    addOption (str, ind) {
      this.each(els => els.options.add(str, ind))
      return this
    }

    eventOn (event, callback) {
      this.each(els => els.addEventListener(event, callback))
      return this
    }

    locationNM () {
      this.each(() => (window.location.href = '/formulas'))
      return this
    }

    location () {
      const el = { any: '' }
      this.each(els => {
        const loc = els.location.href
        const l = loc.substring(loc.indexOf('/', 9) + 1)
        el.any = '/' + l
      })
      return el.any
    }

    back () {
      this.each(() => window.history.back())
      return this
    }

    setAttr (p, v) {
      this.each(els => els.setAttribute(p, v))
      return this
    }

    attribute (p, v) {
      if (!v) {
        const el = { str: '' }
        this.each(els => (el.str = els.getAttribute(p)))
        return el.str
      } else {
        this.each(els => els.setAttribute(p, v))
      }
      return this
    }

    fadeIn (ms) {
      this.each(els => {
        els.style.opacity = 0
        if (ms) {
          let opacity = 0
          const time = setInterval(() => {
            opacity += 50 / ms
            if (opacity >= 1) {
              clearInterval(time)
              opacity = 1
            }
            els.style.opacity = opacity
          }, 50)
        } else {
          els.style.opacity = 1
        }
      })
      return this
    }

    fadeOut (ms) {
      this.each(els => {
        els.style.opacity = 1
        if (ms) {
          let opacity = 1
          const time = setInterval(() => {
            opacity -= 50 / ms
            if (opacity <= 0) {
              clearInterval(time)
              opacity = 0
            }
            els.style.opacity = opacity
          }, 50)
        } else {
          els.style.opacity = 0
        }
      })
      return this
    }

    browser () {
      this.ua = navigator.userAgent
      this.webkitVersion = /version\/(\d+(\.\d+)?)/i
      const result = { name: '', version: '' }
      if (/msie/i.test(this.ua)) {
        result.name = 'IE'
        result.version = this.ua.match(/msie (\d+(\.\d+)+)+/i)[1]
      } else if (/firefox/i.test(this.ua)) {
        result.name = 'Firefox'
        result.version = this.ua.match(/firefox\/(\d+(\.\d+)+)/i)[1]
      } else if (/safari/i.test(this.ua) && !/chrome/i.test(this.ua)) {
        result.name = 'Safari'
        result.version = this.ua.match(this.webkitVersion)[1]
      } else if (/edg/i.test(this.ua)) {
        result.name = 'Edge'
        result.version = this.ua.match(/edg\/(\d+(\.\d+)+)/i)[1]
      } else if (/opera/i.test(this.ua)) {
        result.name = 'Opera'
        result.version = this.ua.match(this.webkitVersion)[1]
      } else if (/chrome/i.test(this.ua)) {
        result.name = 'Chrome'
        result.version = this.ua.match(/chrome\/(\d+(\.\d+)+)/i)[1]
      } else {
        result.name = 'Others'
        result.version = 'NaN'
      }
      return result
    }
  }
  return new Query(selector)
}
